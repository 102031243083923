import ky from 'ky';
import qs from 'query-string';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Params {}

export default async function api<T>(
  path: string,
  params: any = undefined,
  opts: any = {},
): Promise<T | undefined> {
  opts.method = opts.method || 'get';
  const authToken = localStorage.getItem('auth');

  opts.timeout = false;

  if (opts.method !== 'get') {
    opts.json = params;
  } else if (params) {
    path = `${path}?${qs.stringify(params)}`;
  }

  opts.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(opts.headers || {}),
    'Access-Control-Allow-Headers':
      'X-Requested-With, content-type, Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Referrer-Policy': 'no-referrer',
  };
  if (authToken) {
    opts.headers.auth = authToken;
  }

  const response = await ky(
    `${process.env.REACT_APP_API_URL}/api/${path}`,
    opts,
  );

  try {
    const resp = await response.json();

    if (resp?.auth) {
      localStorage.setItem('auth', resp.auth);
    }

    return resp;
  } catch (e) {
    if (!response.ok) {
      throw e;
    }
  }
}

export function get<T>(path: string, opts: RequestInit = {}) {
  return api<T>(path, undefined, opts);
}

export function post<T>(path: string, params: Params, opts: RequestInit = {}) {
  opts.method = 'post';
  return api<T>(path, params, opts);
}

export function put<T>(path: string, params: Params, opts: RequestInit = {}) {
  opts.method = 'put';
  return api<T>(path, params, opts);
}

export function destroy<T>(path: string, opts: RequestInit = {}) {
  opts.method = 'delete';
  return api<T>(path, null, opts);
}
