/* eslint-disable sort-keys */
import Dialog from '@component/Dialog';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
} from '@material-ui/core';
import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import store from '@lib/store';
import BigListItem from '@component/BigListItem';
import DeviceIcon from '@material-ui/icons/VideoLabel';
import { Device } from '@lib/Organization';
import { SelectOperatingRoom } from '@molecules';
import { OperatingRoom } from '@types';
import { put } from '@lib/ApiClient';
import Confirm from '@component/Confirm';

export default observer(function ManageDevicesMenu({ open, onClose }) {
  const state = useLocalObservable(() => ({
    managingDevice: null as Device,
    confirmingDisconnectDevice: null as Device,
    get updateUrl() {
      return (
        `organizations/${store.currentOrganization.id}` +
        `/devices/${
          state.managingDevice?.id || state.confirmingDisconnectDevice.id
        }`
      );
    },
    async confirmDisconnectDevice() {
      const device = await put(state.updateUrl, {
        operatingRoomId: null,
      });
      Object.assign(state.confirmingDisconnectDevice, device);
      state.confirmingDisconnectDevice = null;
    },
    async associateRoomWithDevice({ id: operatingRoomId }: OperatingRoom) {
      const device = await put(state.updateUrl, {
        operatingRoomId,
      });
      Object.assign(state.managingDevice, device);
      state.managingDevice = null;
    },
  }));

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Devices</DialogTitle>
      <SelectOperatingRoom
        open={!!state.managingDevice}
        onCancel={() => (state.managingDevice = null)}
        onSelect={room => state.associateRoomWithDevice(room)}
        getOptionDisabled={({ id: operatingRoomId }) =>
          store.currentOrganization.devices.some(
            device => device.operatingRoomId === operatingRoomId,
          )
        }
      />
      <Confirm
        open={!!state.confirmingDisconnectDevice}
        title={'Disconnect Device'}
        content={() =>
          `Disconnect device ${state.confirmingDisconnectDevice.id}?`
        }
        onConfirm={state.confirmDisconnectDevice}
        onCancel={() => (state.confirmingDisconnectDevice = null)}
      />
      <DialogContent>
        <List>
          {store.currentOrganization.devices.map((device: Device) => {
            const room = store.roomForDevice(device);

            return (
              <BigListItem
                key={`manage-devices-${device.id}`}
                button
                onClick={() => {
                  if (room) {
                    state.confirmingDisconnectDevice = device;
                  } else {
                    state.managingDevice = device;
                  }
                }}
                LeftIcon={DeviceIcon}
                primaryText={device.id}
                secondaryText={
                  room ? (
                    <>
                      {room.name} - {store.facilityForRoom(room).name}
                    </>
                  ) : (
                    'No OR Connected'
                  )
                }
              />
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back</Button>
      </DialogActions>
    </Dialog>
  );
});
