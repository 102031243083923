/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import { IObservableArray } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import TimeAgo from 'timeago-react';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ClockIcon from '@material-ui/icons/Timer';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import useSubmenu from '@lib/useSubmenu';
import { formatPhone } from '@lib/IMask';
import { OrganizationMembershipWith } from '@lib/OrganizationMembership';
import { get } from '@lib/ApiClient';
import { InviteUserDialog } from '@component/InviteUserDialog';
import BigListItem from '@component/BigListItem';
import Dialog from '@component/Dialog';
import { Box, Loading } from '@atoms';

export default observer(function ManageOrganizationUsersMenu({
  organization,
  open,
  onClose,
}) {
  const { submenu, closeSubmenu, makeSubmenuSetter } = useSubmenu({
      names: ['invite-user'],
      onClosed() {
        state.fetchMemberships();
      },
    }),
    state = useLocalObservable(() => ({
      memberships: null as IObservableArray<OrganizationMembershipWith>,
      loadingMemberships: false,
      async fetchMemberships() {
        state.loadingMemberships = true;
        state.memberships = await get(
          `organizations/${organization.id}/memberships`,
        );
        state.loadingMemberships = false;
      },
    }));

  useEffect(() => {
    if (open) {
      state.fetchMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (state.loadingMemberships) return <Loading />;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Users</DialogTitle>
      <DialogContent>
        <List>
          {state.memberships?.map(
            ({
              user: { id, name, email, phone },
              title,
              created_at,
              accepted_at,
            }) => (
              <BigListItem
                key={id}
                LeftIcon={PersonIcon}
                alignItems="flex-start"
              >
                <Box column>
                  {name || '<No Name>'}
                  <Box color="text.secondary" smaller column>
                    {!!title && <Box line>{title}</Box>}
                    <Box line>
                      <EmailIcon />
                      &nbsp;{email}
                    </Box>
                    <Box line>
                      <PhoneIcon />
                      &nbsp;{formatPhone(phone)}
                    </Box>
                    {created_at && !accepted_at && (
                      <Box line>
                        <ClockIcon />
                        &nbsp;Invited&nbsp;
                        <TimeAgo datetime={created_at} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </BigListItem>
            ),
          )}
          <BigListItem
            LeftIcon={PersonAddIcon}
            key="invite-user"
            button
            onClick={makeSubmenuSetter('invite-user')}
            primaryText="Invite a New User"
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back</Button>
      </DialogActions>
      <InviteUserDialog
        organization={organization}
        open={submenu === 'invite-user'}
        onClose={closeSubmenu}
      />
    </Dialog>
  );
});
