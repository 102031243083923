import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import RotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import styles from './monitorViews.module.scss';

interface IScreenDisplay {
  facilityName: string
  frameUrl: string
  roomName: string
}

/**
 * An view level component which expects a single `room` (OperatingRoom Type) passed in
 * This returns a skeleton if no image is found or the current image from the room is possible
 */
export const ScreenDisplay = ({
  facilityName,
  frameUrl,
  roomName,
}: IScreenDisplay) => {
  const [brokenImage, setBroken] = useState(false);
  const [fullScreen, setFull] = useState(false);
  const [rotated, setRotate] = useState(false);

  /**
   * Runs whenever the frameUrl changes - this is to reset the "brokenImage" state
   */
  useEffect(() => {
    if (!brokenImage) {
      return;
    }

    setBroken(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frameUrl]);

  // If the room object doesn't exist, if the framUrl is empty, or if the image can't load - load a skeleton instead
  if (!frameUrl || brokenImage) {
    return (
      <Skeleton animation={false} height={150} variant="rect" width="100%" />
    );
  }

  /**
   * Closes the dialog and resets the rotation
   */
  const closeDialog = () => {
    setFull(false);
    setRotate(false);
  };

  return (
    <>
      <img
        alt="vital frame"
        onClick={() => setFull(true)}
        onError={() => setBroken(true)}
        src={frameUrl}
        width="100%"
      />

      <Dialog
        keepMounted
        maxWidth="lg"
        onClose={closeDialog}
        open={fullScreen}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <div className={styles.imgDialog}>
          <div className={styles.center}>
            <div className={styles.header}>
              <h1>{roomName}</h1>
              <h3>- {facilityName}</h3>
            </div>
            <div
              className={`${styles.imgContainer} ${
                rotated ? styles.rotated : undefined
              }`}
            >
              <img
                width={!rotated ? '100%' : undefined}
                src={frameUrl}
                alt="vital frame"
              />
            </div>
            <IconButton
              className={styles.rotateIcon}
              onClick={() => setRotate(!rotated)}
            >
              <RotateIcon />
              <div>Rotate View</div>
            </IconButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ScreenDisplay;
