/* eslint-disable react/prop-types */
import Dialog from '@component/Dialog';
import SaveButton from '@component/SaveButton';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

export default function Confirm({ title, content, onConfirm, onCancel, open }) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {open && typeof title === 'function' ? title() : title}
      </DialogTitle>
      <DialogContent>
        {open && typeof content === 'function' ? content() : content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <SaveButton color="primary" onClick={onConfirm}>
          Confirm
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
}
