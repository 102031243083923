/* eslint-disable sort-keys */
import {
  makeAutoObservable,
  configure,
  IObservableArray,
  reaction,
  when,
} from 'mobx';
import LogRocket from 'logrocket';
import { IntercomProps } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { IAlert } from '@types';
import { destroy, get, post, put } from '@lib/ApiClient';
import { Device, OrganizationWith } from '@lib/Organization';
import { navigate } from '@lib/history';
import { IAppError, IMonitor, OperatingRoom } from '@types';
import { User } from '@lib/User';
import { UserCable, createUserCable } from '@lib/userCable';
import createCable from '@lib/cable';

configure({
  enforceActions: 'never', // let TypeScript keep us safe
});

const store = {
  adminView: false,
  bootedIntercom: false,
  cable: undefined,
  errors: [] as IAppError[],
  loggingOut: false,
  monitors: [] as IObservableArray<IMonitor>,
  organizations: [] as IObservableArray<OrganizationWith>,
  performedInitialUserFetch: false,
  postLoginDestination: '/dashboard' as string,
  saving: false,
  showLanding: true,
  stopIntercom: null as () => void,
  user: null as User,
  userCableReady: false,

  get userCable(): UserCable {
    if (store.user && store.cable && localStorage.getItem('auth')) {
      return createUserCable(store.cable);
    }
    return null;
  },

  get intercomOpts() {
    return store.user
      ? {
        email: store.user.email,
        userId: store.user.id,
        name: stripPrefixFromName(store.user.name),
        phone: store.user.phone,
        company: store.currentOrganization
          ? {
            companyId: store.currentOrganization.id,
            name: store.currentOrganization.name,
          }
          : null,
      }
      : { email: null, userId: null };
  },

  get currentOrganization(): OrganizationWith | null {
    return store.user
      ? store.organizations.find(
        ({ id }) => id === store.user.currentOrganizationId,
      )
      : null;
  },

  get alerts(): IAlert[] {
    return store.monitors.reduce(
      (alerts, monitor) => alerts.concat(monitor.alerts),
      [],
    );
  },

  /**
   * Determines who the user actually is, including all the user data
   * @param {boolean?} override - If true, will ignore the user object in localStorage
   */
  async loadCurrentUser(override?: boolean) {
    Sentry.addBreadcrumb({ message: 'loading current user' });
    store.userCableReady = false;
    store.cable = createCable();

    try {
      const promises: Promise<any>[] = [
        store.loadOrganizations(),
        store.loadMonitors(),
      ];

      const user = localStorage.getItem('user');

      if (!override && user) {
        await Promise.all(promises);
        store.user = JSON.parse(user);
      } else {
        promises.push(get<User>('current_user'));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, __, storeUser] = await Promise.all(promises);
        store.user = storeUser;
      }
      localStorage.setItem('user', JSON.stringify(store.user));

      if (store.user) {
        Sentry.setUser({
          id: store.user.id,
          email: store.user.email,
        });

        Sentry.setContext('currentOrganization', {
          name: store.currentOrganization.name,
          id: store.currentOrganization.id,
        });

        LogRocket.identify(store.user.id, {
          name: store.user.name,
          email: store.user.email,
        });
      }

      await store.userCable.performWithResponse('hello');
      Sentry.addBreadcrumb({ message: 'loaded current user' });
    } catch (e) {
      if (e.response?.status === 403) {
        store.user = null;
        localStorage.removeItem('user');
      } else {
        throw e;
      }
    } finally {
      store.performedInitialUserFetch = true;
    }
  },

  async loadOrganizations() {
    store.organizations.replace(await get<OrganizationWith[]>('organizations'));
  },

  async loadMonitors() {
    store.monitors.replace(await get<IMonitor[]>('monitors'));
  },

  monitorForRoom({ id }: OperatingRoom) {
    return store.monitors.find(({ operatingRoomId }) => operatingRoomId === id);
  },

  facilityForRoom({ id }: OperatingRoom) {
    return store.currentOrganization.facilities.find(({ operatingRooms }) => {
      return operatingRooms.some(candidate => id === candidate.id);
    });
  },

  roomForMonitor({ operatingRoomId }: IMonitor): OperatingRoom {
    for (const facility of store.currentOrganization.facilities) {
      for (const room of facility.operatingRooms) {
        if (room.id === operatingRoomId) {
          return room;
        }
      }
    }

    throw new Error(`no operating room with id ${operatingRoomId}`);
  },

  roomForDevice({ operatingRoomId }: Device): OperatingRoom {
    if (!operatingRoomId) return null;

    for (const facility of store.currentOrganization.facilities) {
      for (const room of facility.operatingRooms) {
        if (room.id === operatingRoomId) {
          return room;
        }
      }
    }

    throw new Error(`no operating room with id ${operatingRoomId}`);
  },

  get sortedMonitors(): IMonitor[] {
    return store.monitors.slice().sort((a, b) => {
      /**
       * Sorting priority should be:
       * by alerts
       * by status
       * * active
       * * standby
       * * off
       * then finally by name
       */

      if (a.alerts.length !== b.alerts.length) {
        return b.alerts.length - a.alerts.length;
      }

      const aRoom = store.roomForMonitor(a);
      const bRoom = store.roomForMonitor(b);

      if (aRoom.status !== bRoom.status) {
        switch (true) {
          case aRoom.status === 'active':
            return -1;

          case bRoom.status === 'active':
            return 1;

          case aRoom.status === 'standby':
            return -1;

          case bRoom.status === 'standby':
            return 1;
        }
      }

      return aRoom.name.localeCompare(bRoom.name);
    });
  },

  async setOrganization(organization_id: string) {
    store.userCable.perform('clear_monitors', {});
    store.monitors = [] as IObservableArray<IMonitor>;
    store.user.currentOrganizationId = organization_id;
    await put('current_organization', { organization_id });
    await Promise.all([store.loadOrganizations(), store.loadMonitors()]);
    localStorage.setItem('user', JSON.stringify(store.user));
  },

  async logOut({ navigateTo } = { navigateTo: '/' || null }) {
    store.loggingOut = true;
    store.userCable?.unsubscribe();
    await destroy('session');
    localStorage.removeItem('auth');
    store.user = null;
    store.performedInitialUserFetch = false;
    store.monitors.clear();
    store.organizations.clear();
    store.stopIntercom && store.stopIntercom();
    store.stopIntercom = null;
    store.userCableReady = false;
    Sentry.configureScope(scope => scope.setUser(null));
    if (navigateTo) {
      navigate(navigateTo);
    }
    store.loggingOut = false;
  },

  async doPostLogin() {
    await store.loadCurrentUser(true);
  },

  startIntercom(
    boot: (opts: IntercomProps) => void,
    update: (opts: IntercomProps) => void,
    hardShutdown: () => void,
  ) {
    if (store.stopIntercom) {
      return;
    }

    boot(store.intercomOpts);

    const disposer = reaction(
      () => store.intercomOpts,
      opts => {
        update(opts);
      },
    );

    store.stopIntercom = () => {
      disposer();
      hardShutdown();
      store.stopIntercom = null;
    };
  },

  async createMonitor(room: OperatingRoom) {
    await post('monitors', {
      operatingRoomId: room.id,
    });
  },
};

makeAutoObservable(store, null, { autoBind: true, name: 'global store' });

when(
  () => store.performedInitialUserFetch,
  () => {
    reaction(
      () => store.monitors.filter(monitor => !!monitor.discardedAt),
      discardedMonitors =>
        discardedMonitors.forEach(monitor => {
          store.monitors.remove(monitor);
        }),
    );
  },
);

export default store;

// if (process.env.MODE === 'development') {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.store = store;
// }

export class RejectionError extends Error {
  rejection: PromiseRejectionEvent;
}

window.addEventListener('unhandledrejection', rejection => {
  const e = new RejectionError('promise rejection') as IAppError;
  e.rejection = rejection;
  store.errors.push(e);
});

function stripPrefixFromName(name: string) {
  return name.replace(/^.+\.\s*/, '');
}
