import { ChangeEvent, useState } from 'react';

interface FieldProps {
  defaultValue: any
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  variant: 'filled'
}

export default function useFieldState(
  defaultValue: any,
): [value: any, fieldProps: FieldProps] {
  const [state, setState] = useState(defaultValue);

  return [
    state,
    {
      defaultValue,
      onChange({ target: { value } }) {
        setState(value);
      },
      variant: 'filled',
    },
  ];
}
