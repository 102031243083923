/* eslint-disable react/jsx-key */
import React from 'react';
import { GridField, IGridField } from '@atoms';
import styles from './gridFields.module.scss';

export interface IGridFields {
  records: IGridField[]
}

export const GridFields = ({ records }: IGridFields) => (
  <div className={styles.gridFields}>
    {records.map(props => (
      <GridField {...props} />
    ))}
  </div>
);

export default GridFields;
