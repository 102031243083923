import { useEffect, useState } from 'react';

interface Options {
  onChange?: (name: string) => void
  onClosed?: (name: string) => void
  names: string[]
  defaultOpen?: string
}

export default function useSubmenus({
  names,
  onChange,
  onClosed,
  defaultOpen,
}: Options) {
  const [submenu, _setSubmenu] = useState<string | null>(null),
    setSubmenu = (name: string) => {
      if (!names.includes(name)) {
        throw new Error(`${name} must be included in ${names}`);
      }
      if (submenu === name) {
        return;
      }
      _setSubmenu(name);
      onChange && onChange(name);
    };

  useEffect(() => {
    if (defaultOpen) setSubmenu(defaultOpen);
  });

  return {
    closeSubmenu() {
      const name = submenu;
      _setSubmenu(null);
      onClosed && onClosed(name);
    },
    makeSubmenuSetter(name: string) {
      return () => setSubmenu(name);
    },
    setSubmenu,
    submenu,
  };
}
