import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Box } from './';

export const Loading = (props: JSX.IntrinsicAttributes) => {
  return (
    <Box fill alignItems="center" justifyContent="center">
      <CircularProgress {...props} style={{ margin: '0 auto' }} />
    </Box>
  );
};

export default Loading;
