/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dot from '@material-ui/icons/FiberManualRecord';
import Settings from '@material-ui/icons/MoreHoriz';
import { IAlert, IMonitor } from '@types';
import { isAlertPath } from '@lib/alerts';
import store from '@lib/store';
import InPersonCheckCountdown from '@component/InPersonCheckCountdown';
import OperatingRoomMenu from '@component/OperatingRoomMenu';
import { Box } from '@atoms';
import styles from './monitorViews.module.scss';

export const OperatingRoomDetails = observer(function OperatingRoomDetails({
  monitor,
  alert,
}: {
  monitor?: IMonitor
  alert?: IAlert
}) {
  const room = store.roomForMonitor(monitor);
  const facility = store.facilityForRoom(room);
  const isVisitingAlert = isAlertPath(window.location.pathname, alert?.id);
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    isMenuOpen: false,
    openMenu() {
      state.isMenuOpen = true;
    },
    closeMenu() {
      if (isVisitingAlert) {
        navigate('/dashboard');
      }
      state.isMenuOpen = false;
    },
  }));

  const renderStatus = () => {
    if (alert) {
      return 'Alert';
    }

    switch (room.status) {
      case 'off':
        return 'Off';

      case 'standby':
        return 'Standby';

      case 'active':
        return 'In Surgery';

      default:
        return null;
    }
  };

  useEffect(() => {
    if (isVisitingAlert) {
      if (alert) {
        state.openMenu();
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, isVisitingAlert]);

  return (
    <>
      <OperatingRoomMenu
        open={state.isMenuOpen}
        onClose={state.closeMenu}
        room={room}
      />
      <Box fullWidth>
        <Box flexGrow={1} justifyContent="flex-end">
          <Box fullWidth fontSize="125%">
            <div className={styles.roomName}>{room.name}</div>
            <div className={styles.roomDetails}>
              {` - ${facility.name}${room.floor ? ` - ${room.floor} FL` : ''}`}
            </div>
            <div
              className={`${styles.roomStatus} ${
                alert ? styles.alert : styles[room.status]
              }`}
            >
              <Dot /> {renderStatus()}
            </div>
            {alert && (
              <Button
                className={styles.alertButton}
                color="inherit"
                onClick={() => navigate(`/alerts/${alert.id}`)}
                size="small"
                variant="outlined"
              >
                {alert.vitalName.replace('_', ' ')}
              </Button>
            )}
          </Box>
        </Box>
        <InPersonCheckCountdown dueAt={monitor.inPersonCheckDueAt} />
        <IconButton onClick={state.openMenu} className={styles.settings}>
          <Settings />
        </IconButton>
      </Box>
    </>
  );
});

export default OperatingRoomDetails;
