import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntercom } from 'react-use-intercom';
import { addBreadcrumb } from '@sentry/react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { OperatingRoom } from '@types';
import navigation, { fullPath, makeNavigate, navigate } from '@lib/history';
import store from '@lib/store';
import DeviceIssues from '@component/DeviceIssues';
import Dialog from '@component/Dialog';
import { Box } from '@atoms';
import { Monitor } from '@organisms';
import { SelectOperatingRoom } from '@molecules';
import styles from './dashboard.module.scss';

export const Dashboard = observer(() => {
  const [addMonitor, setAddMonitor] = useState(false);
  const { cable } = store;

  const loggedOut =
    store.performedInitialUserFetch && !store.currentOrganization;

  const {
    boot,
    update,
    hardShutdown,
    trackEvent: trackIntercomEvent,
  } = useIntercom();

  const addingMonitor =
    addMonitor ||
    (store.user && navigation.location.pathname === '/add-monitor');

  const addingMonitorId =
    addingMonitor && navigation.searchParams.get('operatingRoomId');

  useEffect(() => {
    if (loggedOut && !store.loggingOut) {
      store.postLoginDestination = fullPath();
      navigate('/sign-in');
    } else {
      store.startIntercom(boot, update, hardShutdown);
    }

    addBreadcrumb({ message: 'rendering dashboard' });

    return store.stopIntercom;
  }, [boot, hardShutdown, loggedOut, update]);

  useEffect(() => {
    if (trackIntercomEvent) {
      trackIntercomEvent('viewedDashboard');
    }
  }, [trackIntercomEvent]);

  const monitorSelected = async (room: OperatingRoom) => {
    await store.createMonitor(room);
    trackIntercomEvent('monitorCreated');
    trackIntercomEvent('monitorAddedFromQRCode');
    navigate('/dashboard');
    setAddMonitor(false);
  };

  if (!store.user || !cable) {
    return null;
  } else {
    return (
      <Box
        fullWidth
        fill
        column
        alignItems="center"
        px={2}
        flexGrow={1}
        bgcolor="background.dark"
        color="text.primary"
      >
        <div className={styles.settings}>
          <Button
            className={styles.addMonitorButton}
            onClick={() => setAddMonitor(true)}
          >
            + Add Monitor
          </Button>
        </div>
        <SelectOperatingRoom
          getOptionDisabled={() => false}
          onCancel={() => {
            makeNavigate('/dashboard');
            setAddMonitor(false);
          }}
          onSelect={monitorSelected}
          open={addingMonitor}
          operatingRoomId={addingMonitorId}
        />
        {store.sortedMonitors.map(monitor => (
          <Monitor cable={cable} key={monitor.id} monitor={monitor} />
        ))}
        <Dialog open={navigation.location.pathname === '/device-issues'}>
          <DialogTitle>Device Issues</DialogTitle>
          <DialogContent>
            <DeviceIssues />
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
});

export default Dashboard;
