/* eslint-disable sort-keys */
import React, { FormEvent, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import isEmail from 'is-email';
import {
  Button,
  ButtonGroup,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/react';
import { post } from '@lib/ApiClient';
import navigation from '@lib/history';
import store from '@lib/store';
import Dialog from '@component/Dialog';
import InfoDialog from '@component/InfoDialog';
import PhoneField from '@component/PhoneField';
import { Box, Loading } from '@atoms';

export const LoginForm = observer(function LoginForm() {
  const state = useLocalObservable(() => ({
    phone: null as string,
    email: null as string,
    didSendCode: false,
    didSendEmail: false,
    incorrectOTP: false,
    verifyingOTP: false,
    signInLinkOTP: navigation.searchParams.get('otp'),
    mode: 'phone' as 'phone' | 'email',
    get maySubmit() {
      return (
        (state.mode === 'phone' && state.phone?.length === 11) ||
        isEmail(state.email)
      );
    },
    async submit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();

      if (state.mode === 'phone') {
        await state.requestOTP();
      } else {
        await state.requstLoginLink();
      }
    },
    async requestOTP() {
      await post('otp', { phone: state.phone });
      state.didSendCode = true;
      Sentry.addBreadcrumb({ message: 'requested OTP' });
    },
    async verifyOTP(otp: string) {
      // eslint-disable-next-line no-console
      console.log('Verifying...');
      try {
        state.verifyingOTP = true;
        await post('sessions', { otp });
        await store.doPostLogin();
      } catch (e) {
        const { message } = (await e.response?.json()) || {};

        if (message === 'invalid code') {
          state.incorrectOTP = true;
        } else {
          store.errors.push(e);
        }
      } finally {
        state.verifyingOTP = false;
      }
    },

    async requstLoginLink() {
      await post('login_email', { email: state.email });
      state.didSendEmail = true;
      Sentry.addBreadcrumb({ message: 'request login link' });
    },
  }));

  useEffect(() => {
    if (state.signInLinkOTP) {
      state.verifyOTP(state.signInLinkOTP);
    } else if (!store.user) {
      store.logOut({ navigateTo: null });
      Sentry.addBreadcrumb({
        message: 'displayed login form',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={true}>
      <DialogTitle title="Sign In">Sign In</DialogTitle>
      {state.verifyingOTP ? (
        <DialogContent>
          <Box column>
            <Box>Authenticating...</Box>
            <Loading />
          </Box>
        </DialogContent>
      ) : state.didSendCode ? (
        <DialogContent>
          <Typography paragraph>
            We&apos;ve texted you a code. Enter it below.
          </Typography>
          <TextField
            autoFocus
            fullWidth
            disabled={state.verifyingOTP}
            label="Code"
            error={state.incorrectOTP}
            helperText={state.incorrectOTP ? 'Incorrect code' : undefined}
            inputProps={{ type: 'tel', maxLength: 6 }}
            onChange={({ target: { value: code } }) => {
              if (code?.length === 6) {
                state.verifyOTP(code);
              }
            }}
          />
        </DialogContent>
      ) : state.didSendEmail ? (
        <DialogContent>
          <Box>
            We&apos;ve sent login instructions to your email address. You may close
            this tab or window.
          </Box>
        </DialogContent>
      ) : (
        <form
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={state.submit}
        >
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box column>
              {state.incorrectOTP ? (
                <Box fullWidth py={2}>
                  <Alert severity="error">
                    Invalid or expired {state.signInLinkOTP ? 'link' : 'code'}
                  </Alert>
                </Box>
              ) : null}
              <Box fullWidth justifyContent="center">
                <ButtonGroup>
                  <Button
                    color="primary"
                    onClick={() => (state.mode = 'phone')}
                    variant={state.mode === 'phone' ? 'contained' : 'outlined'}
                  >
                    Phone
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => (state.mode = 'email')}
                    variant={state.mode === 'email' ? 'contained' : 'outlined'}
                  >
                    Email
                  </Button>
                </ButtonGroup>
              </Box>
              {state.mode === 'phone' ? (
                <>
                  <Box
                    py={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Enter your phone number and we&apos;ll text you a one-time
                    password.
                    <InfoDialog title="One Time Password">
                      A secure way to sign in without having to remember a
                      password
                    </InfoDialog>
                  </Box>
                  <PhoneField
                    onChange={(phone: string) => (state.phone = phone)}
                    autoFocus
                  />
                </>
              ) : (
                <>
                  <Box
                    py={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Enter your email address and we&apos;ll send you a link to log
                    in.
                  </Box>
                  <TextField
                    fullWidth
                    label="Email"
                    onChange={e => (state.email = e.target.value)}
                    autoFocus
                  />
                </>
              )}
            </Box>
            <Box pt={2}>
              <Button type="submit" disabled={!state.maySubmit}>
                Send {state.mode === 'email' ? 'Link' : 'Code'}
              </Button>
            </Box>
          </DialogContent>
        </form>
      )}
    </Dialog>
  );
});

export default LoginForm;
