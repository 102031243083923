/* eslint-disable @typescript-eslint/ban-ts-comment */
import IMask from 'imask';

const phoneMask = '+{1} (000) 000-0000';
// @ts-ignore
const formatPhone = IMask.createPipe({ mask: phoneMask });
// @ts-ignore
const formatDate = IMask.createPipe({ mask: Date });

export { phoneMask, formatPhone, formatDate };

export default IMask;
