/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import markSVG from '@assets/white-shield.svg';
import logoTypeSVG from '@assets/logotype-blue.svg';
import { veryWideCentered } from '@lib/theme';
import store from '@lib/store';
import { Box } from '@atoms';
import styles from './topBar.module.scss';

function LogoPart({ hideLogoType }) {
  return (
    <Box className={styles.logo}>
      <Box href="/">
        <img
          src={markSVG}
          width="30"
          height="auto"
          alt="mark"
          style={{
            margin: '8px',
          }}
        />
      </Box>
      {hideLogoType ? null : (
        <Box
          display={{ md: 'flex', xs: 'none' }}
          px={2}
          href="/"
          alignItems="center"
        >
          <img src={logoTypeSVG} width="125px" height="auto" alt="logo" />
        </Box>
      )}
      Guardian
    </Box>
  );
}

/**
 * Creates a bar to show now many alerts there currently are
 */
const AlertBar = ({ alerts }) => {
  const alertCount = alerts.length;

  if (alertCount > 0) {
    return (
      <div className={styles.alertBox}>
        {alertCount} Alert{alertCount > 1 ? 's' : ''}
      </div>
    );
  }

  return null;
};

export const TopBar = ({
  basePadding,
  children,
  hideLogoType = false,
  bgcolor,
  color,
}: {
  basePadding: number
  bgcolor?: string
  children: React.ReactChild[]
  color?: string
  hideLogoType?: boolean
}) => {
  return (
    <>
      <Box
        id="guardian-top-bar"
        p={basePadding}
        alignItems="center"
        justifyContent="space-between"
        fullWidth
        bgcolor={bgcolor ? bgcolor : undefined}
        color={color ? color : undefined}
        {...veryWideCentered}
      >
        <LogoPart hideLogoType={hideLogoType} />
        {children}
      </Box>
      <AlertBar alerts={store.alerts} />
    </>
  );
};

export default TopBar;
