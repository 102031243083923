/* eslint-disable react/prop-types */
import BigListItem from '@component/BigListItem';
import Dialog from '@component/Dialog';
import PeopleIcon from '@material-ui/icons/People';
import FacilityIcon from '@material-ui/icons/Business';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import useSubmenu from '@lib/useSubmenu';
import ManageOrganizationUsersMenu from '@component/ManageOrganizationUsersMenu';
import ManageFacilitiesMenu from '@component/ManageFacilitiesMenu';
import ManageDevicesMenu from '@component/ManageDevicesMenu';
import DeviceIcon from '@material-ui/icons/VideoLabel';

export default function ManageOrganizationMenu({
  organization,
  onClose,
  open,
}) {
  const { submenu, setSubmenu, closeSubmenu } = useSubmenu({
    names: ['manage-users', 'manage-facilities', 'manage-devices'],
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <ManageOrganizationUsersMenu
        open={submenu === 'manage-users'}
        onClose={closeSubmenu}
        organization={organization}
      />
      <ManageFacilitiesMenu
        open={submenu === 'manage-facilities'}
        onClose={closeSubmenu}
      />
      <ManageDevicesMenu
        open={submenu === 'manage-devices'}
        onClose={closeSubmenu}
      />
      <DialogTitle>Manage {organization.name}</DialogTitle>
      <DialogContent>
        <List>
          <BigListItem
            button
            onClick={() => setSubmenu('manage-users')}
            LeftIcon={PeopleIcon}
          >
            <ListItemText primary="Manage Users" />
          </BigListItem>
          <BigListItem
            button
            onClick={() => setSubmenu('manage-facilities')}
            LeftIcon={FacilityIcon}
            primaryText="Manage Facilities"
          />
          <BigListItem
            button
            onClick={() => setSubmenu('manage-devices')}
            LeftIcon={DeviceIcon}
            primaryText="Manage Devices"
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back</Button>
      </DialogActions>
    </Dialog>
  );
}
