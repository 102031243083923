/* eslint-disable react/require-default-props */
import { Dialog as DIALOG, DialogProps, Slide } from '@material-ui/core';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const Dialog = ({
  children,
  ...props
}: DialogProps & {
  children:
    | React.ReactElement
    | React.ReactElement[]
    | (() => React.ReactElement)
}) => {
  return (
    <DIALOG fullScreen {...props} TransitionComponent={Transition}>
      {(() =>
        props.open
          ? typeof children === 'function'
            ? children()
            : children
          : null)()}
    </DIALOG>
  );
};

export default Dialog;
