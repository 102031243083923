/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import Divider from '@material-ui/core/Divider';
import { OperatingRoom, IMonitor } from '@types';
import store from '@lib/store';
import { Box } from '@atoms';
import { OperatingRoomDetails, ScreenDisplay, VitalsDisplay } from './views';
import styles from './monitor.module.scss';

export const Monitor = observer(function Monitor({
  monitor,
  cable,
}: {
  monitor?: IMonitor
  cable?: any
}) {
  const room = store.roomForMonitor(monitor);
  const facility = store.facilityForRoom(room);
  const alerts = monitor.alerts;
  const hasAlert = alerts?.length;
  const topAlert = hasAlert && alerts[0];

  const state = useLocalObservable(() => ({
    roomCable:
      room &&
      cable?.subscriptions.create(
        {
          channel: 'OperatingRoomChannel',
          id: room.id,
        },
        {
          received(newAttributes: OperatingRoom) {
            Object.assign(room, newAttributes);
          },
        },
      ),
    monitorCable:
      monitor &&
      cable?.subscriptions.create(
        {
          channel: 'MonitorChannel',
          id: monitor.id,
        },
        {
          received(newAttributes: IMonitor) {
            Object.assign(monitor, newAttributes);
          },
        },
      ),
  }));

  useEffect(
    () => () => {
      if (state.monitorCable) {
        state.monitorCable.unsubscribe();
      }
    },
    [state.monitorCable],
  );

  useEffect(
    () => () => {
      if (state.roomCable) {
        state.roomCable.unsubscribe();
      }
    },
    [state.roomCable],
  );

  return (
    <Box
      alignItems="center"
      bgcolor="black"
      borderRadius={4}
      className={hasAlert ? styles.alert : undefined}
      color="white"
      column
      data-monitor-id={monitor?.id}
      fullWidth
      justifyContent="center"
      mb={1}
      p={2}
      zIndex={5}
    >
      <OperatingRoomDetails
        monitor={monitor}
        alert={hasAlert ? topAlert : null}
      />

      {room.status === 'active' && (
        <>
          <Divider className={styles.divider} />
          <ScreenDisplay
            facilityName={facility.name}
            frameUrl={room.frameUrl}
            roomName={room.name}
          />
          <Divider className={styles.divider} />
        </>
      )}
      {store.adminView && (
        <VitalsDisplay vitals={room.vitals} vitalSet={room.vitalSet} />
      )}
    </Box>
  );
});

export default Monitor;
