/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Loading } from '@atoms';

export const Suspense = ({ children }) => (
  <React.Suspense fallback={<Loading />} children={children} />
);

export default Suspense;
