import { IMonitor } from '@types';
import store from '@lib/store';
import { set, when } from 'mobx';
import { v4 as uuid } from 'uuid';

type PerformWithResponse = <T>(name: string, data?: object) => Promise<T>

export interface UserCable extends ActionCable.Channel {
  performWithResponse?: PerformWithResponse
}

interface UserChannelMessage {
  action: 'create' | 'update' | 'delete'
  type: 'Monitor' | 'User'
  attrs: any
  requestId: string
  response: any
}

export interface UserChannel {
  performWithResponse?: PerformWithResponse
}

const resolvers = [];

export function createUserCable(cable) {
  return cable.subscriptions.create(
    {
      channel: 'UserChannel',
    },
    {
      connected() {
        store.userCableReady = true;
      },
      async performWithResponse<T>(name: string, data = {}) {
        await when(() => store.userCableReady);

        const requestId = uuid();
        const promise = new Promise((res, rej) => {
          const failTimeout = setTimeout(() => {
            delete resolvers[requestId];
            rej('cable request timeout');
          }, 5000);

          resolvers[requestId] = (response: any) => {
            clearTimeout(failTimeout);
            res(response);
          };

          this.perform(name, { requestId, ...data });
        });
        const response = await promise;

        return response as T;
      },

      received({
        action,
        type,
        attrs,
        requestId,
        response,
      }: UserChannelMessage) {
        if (
          action === 'create' &&
          store.monitors.find(({ id }) => id === attrs.id)
        ) {
          // eslint-disable-next-line no-console
          console.error(`Got create for monitor ${attrs.id} twice`);
          return;
        }

        if (action === 'update' && type === 'Monitor') {
          const monitor = store.monitors.find(({ id }) => id === attrs.id);

          if (attrs.discardedAt) {
            store.monitors.remove(monitor);
          } else {
            set(monitor, attrs);
          }
        } else if (action === 'create' && type === 'Monitor') {
          store.monitors.push(attrs as IMonitor);
        } else if (action === 'update' && type === 'User') {
          if (!store.user) return;
          set(store.user, attrs);
        } else if (requestId) {
          resolvers[requestId](response);
        }
      },

    },
  );
}
