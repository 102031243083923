/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sort-keys */
/* eslint-disable sort-vars */
import { grey } from '@material-ui/core/colors';
import { createTheme, Theme } from '@material-ui/core/styles';
import c from 'color';

const maxWidth = '500px',
  veryWide = '1250px',
  maxWidthCentered = {
    alignItems: 'start',
    maxWidth,
    margin: '0 auto',
  },
  veryWideCentered = {
    ...maxWidthCentered,
    maxWidth: veryWide,
  },
  base = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 275,
        md: 500,
        lg: 700,
        xl: 700,
      },
    },
    props: {
      AppBar: {
        position: 'sticky',
      },
      MuiDialog: {
        fullWidth: true,
      },
      MuiInputBase: {
        fullWidth: true,
      },
      // @ts-ignore
      MuiAutocomplete: {
        fullWidth: true,
        autoComplete: true,
        autoHighlight: true,
        autoSelect: true,
        openOnFocus: false,
        blurOnSelect: true,
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          ...maxWidthCentered,
        },
      },
      MuiList: {
        root: {
          width: '100%',
        },
      },
      MuiDialog: {
        container: {
          ...maxWidthCentered,
        },
      },
      MuiCssBaseline: {
        '@global': {
          'html, body, #app': {
            minHeight: '100vh',
          },
          body: {
            backgroundColor: '#222',
          },
          '.fill': {
            height: '100%',
            width: '100%',
          },
          '.sentry-error-embed-wrapper': {
            zIndex: '2000 !important' as unknown as number,
            '& .sentry-error-embed': {
              maxWidth: `${maxWidth} !important`,
              '& .powered-by': {
                display: 'none',
              },
            },
          },
        },
      },
      MuiSkeleton: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
      MuiSlider: {
        thumb: {
          width: '15px',
          height: '15px',
        },
        markLabel: {
          fontSize: '1.25rem',
        },
        // the bubble above an active slider
        valueLabel: {
          '& > span': {
            width: '50px',
            height: '50px',
          },
          fontSize: '1.2rem',
          left: '-20px',
          top: '-60px',
        },
      },
      MuiAlert: {
        root: {
          width: '100%',
        },
      },
    },
  };

export { maxWidth, maxWidthCentered, veryWideCentered };

const coolors = {
  'Languid Lavender': '#cfc4d4',
  Glaucous: '#6283a7',
  'Dark Purple': '#2c0e37',
  Patriarch: '#690375',
  'Mulberry Crayola': '#cb429f',
};

export default createTheme(base, {
  palette: {
    background: {
      dark: 'rgb(66, 66, 66)',
    },
    text: {
      secondary: '#bfbfbf',
      grey: grey[500],
    },
    bloodPressure: 'red',
    heartRate: '#37eb34',
    co2: 'white',
    oxygen: 'cyan',
    primary: {
      light: c(coolors.Glaucous).lighten(50).hex(),
      main: coolors.Glaucous,
      dark: c(coolors.Glaucous).darken(50).hex(),
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  props: {
    // just hiding this from typescript down here
    // TODO why doesn't it work above?
    MuiTextField: {
      variant: 'filled',
    },
    MuiSvgIcon: {
      fontSize: 'inherit',
    },
  },
}) as Theme;
