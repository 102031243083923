import React, { useEffect, useState } from 'react';
import { get } from '@lib/ApiClient';
import { IAlert } from '@types';
import { AlertLogTemplate } from '@templates';

/**
 * A template for the alert log page
 * Displays all the passed in alerts in a table
 */
export const AlertLog = () => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [loading, setLoading] = useState(true);

  /**
   * Sends an API request to return all the alerts from the database
   */
  const fetchAlerts = () => {
    setLoading(true);
    get<IAlert[]>('alert_logs/all')
      .then(alertResponse => setAlerts(alertResponse))
      .finally(() => setLoading(false));
  };

  useEffect(fetchAlerts, []);

  return <AlertLogTemplate alerts={alerts} loading={loading} />;
};

export default AlertLog;
