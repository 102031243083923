/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import Dialog from '@component/Dialog';
import packageJson from '../../../package.json';

const { version } = packageJson;

interface IErrorDialogProps {
  error?: Error
  resetError: () => void
}

export const ErrorDialog = (
  { error, resetError }: IErrorDialogProps = {
    resetError: () => null,
  },
) => {
  const errorDetails = error?.toString() || 'Unknown Error';

  return (
    <Dialog open={!!error}>
      <DialogTitle>Error!</DialogTitle>
      <DialogContent>
        <Box fontSize="125%">
          Looks like our app is having some trouble. We&apos;ve been automatically
          notified.
        </Box>
        <Box mt={2}>
          If you need us urgently,{' '}
          <Link href="mailto:support@vigilantmedicalsolutions.com">
            email our support team
          </Link>
          .
        </Box>
        <Box mt={3}>
          <Box fontSize="125%" fontWeight="bold">
            Error Details
          </Box>
          <Box>{errorDetails}</Box>
          <Box>Version: {version}</Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetError}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
