import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import SMSIcon from '@material-ui/icons/Sms';
import { IAlert } from '@types';
import store from '@lib/store';
import { Box } from '@atoms';

const RenderAlert = (alert: IAlert) => {
  const [bail, setBail] = useState<boolean>(true);
  const { id, message } = alert;

  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => setBail(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [alert]);

  if (bail || !alert) {
    return null;
  }

  return (
    <Box key={id} bgcolor="gray" borderRadius={4} color="white" p={2}>
      <Box fontSize="300%" mr={2}>
        <SMSIcon fontSize="inherit" />
      </Box>
      <Box column>
        <Box
          fontSize="150%"
          alignItems="center"
          width="300px"
          fontWeight="bold"
          mb={2}
        >
          (404) 341-6426
        </Box>
        <Box fontSize="125%">{message}</Box>
      </Box>
    </Box>
  );
};

export default observer(function Alerts() {
  return (
    <Box
      zIndex={1000}
      position="fixed"
      top="10px"
      left="0"
      right="0"
      fullWidth
      justifyContent="center"
    >
      {store.alerts.map(RenderAlert)}
    </Box>
  );
});
