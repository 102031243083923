/* eslint-disable sort-keys */
/* eslint-disable sort-vars */
import React from 'react';
import { observer } from 'mobx-react';
import { Alert, Skeleton as _Skeleton } from '@material-ui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@material-ui/core';
import AlertIcon from '@material-ui/icons/NotificationsActive';
import CheckInIcon from '@material-ui/icons/TouchApp';
import PhoneIcon from '@material-ui/icons/Phone';
import SettingsIcon from '@material-ui/icons/Settings';
import StopMonitoringIcon from '@material-ui/icons/AlarmOff';
import WarningIcon from '@material-ui/icons/Warning';
import { destroy, post, put } from '@lib/ApiClient';
import { OperatingRoom } from '@types';
import store from '@lib/store';
import useSubmenus from '@lib/useSubmenu';
import { EditAlerts } from '@component/pages';
import BigListItem from '@component/BigListItem';
import Confirm from '@component/Confirm';
import Dialog from '@component/Dialog';
import { Box } from '@atoms';

export default observer(function OperatingRoomMenu({
  room,
  open,
  onClose,
}: {
  room: OperatingRoom
  open: boolean
  onClose: () => void
}) {
  const monitor = store.monitorForRoom(room),
    { alerts, configuringVital } = monitor,
    hasAlert = alerts?.length,
    topAlert = hasAlert ? alerts[0] : null,
    { submenu, makeSubmenuSetter, closeSubmenu } = useSubmenus({
      names: ['alerts', 'confirmingCheckIn', 'confirmingRemoveMonitor'],
      defaultOpen: configuringVital ? 'alerts' : undefined,
    }),
    clearAlert = async function (reportedFalse = false) {
      await put(`alerts/${topAlert.id}`, {
        clearedAt: new Date().toISOString(),
        reportedFalse,
      });
      onClose();
    },
    content = hasAlert ? (
      <Box fullWidth column>
        <Alert severity="warning">{topAlert.message}</Alert>
        <List>
          <BigListItem
            button
            component="a"
            href={`tel:${room.phone}`}
            LeftIcon={PhoneIcon}
          >
            Call OR
          </BigListItem>
          <BigListItem
            button
            onClick={async () => {
              await clearAlert();
            }}
            LeftIcon={StopMonitoringIcon}
          >
            Clear
          </BigListItem>
          <BigListItem
            button
            onClick={async () => {
              await clearAlert(true);
            }}
            LeftIcon={WarningIcon}
          >
            Report false
          </BigListItem>
        </List>
      </Box>
    ) : (
      <>
        <List>
          <BigListItem
            button
            onClick={makeSubmenuSetter('alerts')}
            LeftIcon={SettingsIcon}
          >
            Configure Alerts
          </BigListItem>
          <BigListItem
            button
            onClick={makeSubmenuSetter('confirmingCheckIn')}
            LeftIcon={CheckInIcon}
          >
            Check In
          </BigListItem>
          <BigListItem
            button
            onClick={makeSubmenuSetter('confirmingRemoveMonitor')}
            LeftIcon={StopMonitoringIcon}
          >
            Stop Monitoring
          </BigListItem>
          {store.currentOrganization.features.demo ? (
            <>
              <BigListItem
                button
                onClick={async () => {
                  Object.assign(
                    room,
                    await put(`operating_rooms/${room.id}`, {
                      demoCondition:
                        room.demoCondition === 'crash_pulse'
                          ? 'ok'
                          : 'crash_pulse',
                    }),
                  );
                  onClose();
                }}
                LeftIcon={AlertIcon}
              >
                DEMO: {room.demoCondition === 'crash_pulse' ? 'Clear ' : ''}Heart
                Rate Crash
              </BigListItem>
              <BigListItem
                button
                onClick={async () => {
                  Object.assign(
                    room,
                    await put(`operating_rooms/${room.id}`, {
                      demoCondition:
                        room.demoCondition === 'crash_o2' ? 'ok' : 'crash_o2',
                    }),
                  );
                  onClose();
                }}
                LeftIcon={AlertIcon}
              >
                DEMO: {room.demoCondition === 'crash_o2' ? 'Clear ' : ''}O2 Crash
              </BigListItem>
            </>
          ) : null}
        </List>
      </>
    );

  return (
    <>
      <Confirm
        open={submenu === 'confirmingRemoveMonitor'}
        title={'Remove Monitor'}
        content={() => `Stop monitoring ${room.name}?`}
        onConfirm={async () => {
          store.saving = true;
          await destroy(`monitors/${monitor.id}`);
          store.saving = false;
          store.monitors.remove(monitor);
          onClose();
        }}
        onCancel={closeSubmenu}
      />
      <Confirm
        open={submenu === 'confirmingCheckIn'}
        title={'Check In'}
        content={() => `Confirm you are at ${room.name}`}
        onConfirm={async () => {
          await post('in_person_checks', { monitorId: monitor.id });
          closeSubmenu();
        }}
        onCancel={closeSubmenu}
      />
      <EditAlerts
        open={submenu === 'alerts'}
        onClose={() => {
          monitor.configuringVital = null;
          closeSubmenu();
        }}
        monitor={monitor}
      />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {room.name} - {store.facilityForRoom(room).name}
        </DialogTitle>
        <DialogContent>
          <Box fontSize="125%" fill>
            {content}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
