import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  CssBaseline,
  ThemeProvider,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { ErrorBoundary } from '@sentry/react';
import store from '@lib/store';
import theme from '@lib/theme';
import { ErrorDialog } from '@molecules';
import { MainLayout } from '@templates';
import { AlertDetails, AlertLog, Dashboard, LoginForm } from '@pages';

const ErrorThrower = observer(() => {
  useEffect(() => {
    if (store.errors.length) {
      const error = store.errors.shift();
      if (!error.shown) {
        throw error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.errors.length]);

  return null;
});

export default observer(function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!store.performedInitialUserFetch) {
      store.loadCurrentUser().finally(() => {
        setLoading(false);
      });
      return;
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.performedInitialUserFetch]);

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ErrorBoundary
            showDialog
            fallback={({ error, resetError }) => (
              <ErrorDialog error={error} resetError={resetError} />
            )}
          >
            <ErrorThrower />
            <Routes>
              {!store.user ? (
                <Route path="*" element={<LoginForm />} />
              ) : (
                <Route element={<MainLayout />}>
                  {store.user.admin && (
                    <>
                      <Route path="/alert-log/:id" element={<AlertDetails />} />
                      <Route path="/alert-log" element={<AlertLog />} />
                    </>
                  )}
                  <Route path="/alerts/:id" element={<Dashboard />} />
                  <Route path="*" element={<Dashboard />} />
                </Route>
              )}
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </IntercomProvider>
  );
});
