import { createBrowserHistory } from 'history';
import { createObservableHistory } from 'mobx-observable-history';

const history = createBrowserHistory(),
  navigation = createObservableHistory(history);

export default navigation;

export function navigate(path: string, { replace } = { replace: false }) {
  if (window.location.pathname === path) return;
  else if (replace) history.replace(path);
  else history.push(path);
}

export function fullPath() {
  const maybeSearch = window.location.search.length
    ? window.location.search.toString()
    : '';

  return `${window.location.pathname}${maybeSearch}`;
}

interface MakeNavigateOpts {
  then?: () => void
}

export function makeNavigate(path: string, { then }: MakeNavigateOpts = {}) {
  if (path[0] !== '/') path = `/${path}`;

  return function () {
    navigate(path);
    if (then) then();
  };
}
