/* eslint-disable react/require-default-props */
import React from 'react';
import styles from './gridField.module.scss';

export interface IGridField {
  label: string
  value?: string
  warning?: boolean
}

export const GridField = ({ label, value, warning }: IGridField) => (
  <div className={`${styles.field} ${!warning ? '' : styles.warning}`}>
    <div className={styles.label}>{label}</div>
    {value && <div className={styles.value}>{value}</div>}
  </div>
);

export default GridField;
