/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { matchSorter } from 'match-sorter';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Facility } from '@lib/Facility';
import { OperatingRoom } from '@types';
import store from '@lib/store';
import Dialog from '@component/Dialog';
import SelectFacility from '@component/SelectFacility';
import { Box } from '@atoms';

export const SelectOperatingRoom = observer(function SelectOperatingRoom({
  onSelect,
  onCancel,
  open,
  getOptionDisabled,
  operatingRoomId,
}: {
  onSelect: (operatingRoom: OperatingRoom) => void
  onCancel: () => void
  open: boolean
  getOptionDisabled: (operatingRoom: OperatingRoom) => boolean
  operatingRoomId?: string
}) {
  const state = useLocalObservable(() => ({
      facility: null as Facility,
      operatingRoom: null as OperatingRoom,
      get maySubmit() {
        return !!state.operatingRoom;
      },
      setOperatingRoom(operatingRoom: OperatingRoom) {
        state.operatingRoom = operatingRoom;
      },
      setFacility(facility: Facility) {
        state.facility = facility;
      },
      confirm() {
        state.setFacility(null);
        onSelect(state.operatingRoom);
      },
    })),
    { facilities } = store.currentOrganization;

  useEffect(() => {
    if (facilities.length === 1) {
      state.setFacility(facilities[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length]);

  useEffect(() => {
    if (operatingRoomId) {
      state.facility = store.currentOrganization.facilities.find(f => {
        const room = f.operatingRooms.find(r => r.id === operatingRoomId);
        if (!room) return false;
        state.operatingRoom = room;
        return true;
      });
    }
  });

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Select Operating Room</DialogTitle>
      <DialogContent>
        <SelectFacility
          defaultValue={state.facility}
          onChange={state.setFacility}
          facilities={store.currentOrganization.facilities}
        />
        {state.facility ? (
          <Box mt={2}>
            <Autocomplete
              getOptionDisabled={getOptionDisabled}
              getOptionLabel={(option: OperatingRoom) => option.name}
              options={state.facility.operatingRooms}
              defaultValue={state.operatingRoom}
              filterOptions={(options, { inputValue }) =>
                matchSorter(options, inputValue, { keys: ['name'] })
              }
              onChange={(_, operatingRoom: OperatingRoom) =>
                state.setOperatingRoom(operatingRoom)
              }
              renderInput={props => (
                <TextField
                  {...props}
                  name="room"
                  label="Operating Room"
                  helperText="Search by name, floor..."
                />
              )}
            />
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={!state.maySubmit} onClick={state.confirm}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SelectOperatingRoom;
