import React from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { maxWidth } from '@lib/theme';
import { Box, Suspense } from '@atoms';
import { AppMenu } from '@pages';

/**
 * Layout used for any authenticated user
 */
export const MainLayout = observer(function MainLayout() {
  return (
    <Box
      mx="auto"
      maxWidth={maxWidth}
      column
      minHeight="100vh"
      boxShadow="black 0 0 20px"
    >
      <AppMenu />
      <Suspense>
        <Outlet />
      </Suspense>
    </Box>
  );
});

export default MainLayout;
