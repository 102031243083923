/* eslint-disable react/prop-types */
import { phoneMask } from '@lib/IMask';
import useMask from '@lib/useMask';
import { TextField } from '@material-ui/core';
import React from 'react';

export default function PhoneField({ onChange, ...props }) {
  const { ref } = useMask({
    mask: phoneMask,
    onChange,
  });

  return (
    <TextField
      fullWidth
      label="Phone"
      inputProps={{ ref, type: 'tel' }}
      {...props}
    />
  );
}
