import { createConsumer } from '@rails/actioncable';

export const createCable = () =>
  createConsumer(
    `${process.env.REACT_APP_API_WS_URL}?auth=${encodeURIComponent(
      localStorage.getItem('auth') || '',
    )}`,
  );

export default createCable;
