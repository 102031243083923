/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
import BigListItem from '@component/BigListItem';
import Dialog from '@component/Dialog';
import { Facility } from '@lib/Facility';
import {
  DialogTitle,
  DialogContent,
  List,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { observable } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';
import React, { ChangeEvent, ReactElement } from 'react';

interface FormFor<T> {
  model: T
  save: () => Promise<T>
  errors: any
  dirty: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  Form: ({ children }) => ReactElement
}

function formFor<T>(model: T): FormFor<T> {
  const form = observable({
    model,
    save: async () => {
      // form.saving = true
      // const result = await patch(model, form.changes)

      // if (result.errors) form.errors = errors

      // form.saving = false
      // form.changedFields.clear()

      // return result
    },
    errors: null,
    onChange: async (e: ChangeEvent<HTMLInputElement>) => {
      form.changedFields.add(e.target.name);
    },
    changedFields: new Set<string>([]),
    get dirty() {
      return form.changedFields.size > 0;
    },
    Form: ({ children }) => {
      return (
        <form
          onSubmit={e => {
            e.preventDefault();
            form.save();
          }}
        >
          {children}
        </form>
      );
    },
  });

  return form;
}

export default observer(function EditFacility({
  facility,
  onClose,
}: {
  facility: Facility
  onClose: () => void
}) {
  const state = useLocalObservable(() => ({
    form: formFor(facility),
  }));

  return (
    <Dialog open>
      <DialogTitle>Manage Facility</DialogTitle>
      <DialogContent>
        <state.form.Form>
          <List>
            <BigListItem>
              <TextField
                fullWidth
                label="Name"
                name="name"
                error={state.form.errors?.name}
                defaultValue={facility.name}
                onChange={state.form.onChange}
              />
            </BigListItem>
          </List>
        </state.form.Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back</Button>
        <Button onClick={state.form.save} disabled={!state.form.dirty}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
