import React, { useState, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
} from '@material-ui/core';
import { defaultSettings } from '@lib/Case';
import { IMonitor } from '@types';
import { put } from '@lib/ApiClient';
import store from '@lib/store';
import { Box } from '@atoms';
import { ConfigureVital } from '@component/organisms';
import Dialog from '@component/Dialog';

const editReducer = (state, { vital, editing }) => ({
  ...state,
  [vital]: editing,
});

export const EditAlerts = observer(function EditAlerts({
  monitor,
  open,
  onClose,
  ...dialogProps
}: {
  monitor: IMonitor
  onClose: () => void
} & DialogProps) {
  const [editing, dispatchEditing] = useReducer(editReducer, {});
  const isEditing = Object.keys(editing).some(key => editing[key]);

  const room = store.roomForMonitor(monitor);

  const currentCase = room?.case;
  const settings = currentCase?.alertSettings;
  const facility = room?.facility || store.facilityForRoom(room);
  const [loading, setLoading] = useState(false);

  const patchMonitor = async (patchData: Partial<typeof defaultSettings>) => {
    Object.assign(
      monitor,
      await put(`monitors/${monitor.id}`, { settings: patchData }),
    );
  };

  const resetAll = () => {
    setLoading(true);
    patchMonitor(defaultSettings).finally(() => {
      setLoading(false);
    });
  };

  const vitals = [
    {
      key: 'bpSystolic',
      name: 'Blood Pressure - Systolic',
    },
    {
      key: 'bpDiastolic',
      name: 'Blood Pressure - Diastolic',
    },
    {
      key: 'heartRate',
      name: 'Heart Rate',
    },
    {
      key: 'oxygen',
      name: 'Oxygen',
    },
  ];

  return (
    <Dialog open={open} {...dialogProps}>
      <DialogTitle>
        {room?.name}
        {facility ? ` - ${facility.name}` : ''}
      </DialogTitle>
      <DialogContent>
        <Box>
          You will receive an alert when one of the following conditions are
          triggered. Tap a row to customize the thresholds for this OR, or reset
          to the default settings.
        </Box>
        <List key={+loading}>
          {vitals.map(({ key, name }) => (
            <ConfigureVital
              editing={!!editing[key]}
              initialValue={settings[key]}
              key={key}
              loading={loading}
              name={name}
              onChange={limits => patchMonitor({ [key]: limits })}
              onEditStateChange={isEditing =>
                dispatchEditing({ editing: isEditing, vital: key, })
              }
              vitalKey={key}
            />
          ))}
          <Box mt={2}>
            <Button onClick={resetAll} variant="contained" color="primary">
              Reset All to Default
            </Button>
          </Box>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isEditing}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
});
