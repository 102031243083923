/* eslint-disable react/no-children-prop */
/* eslint-disable react/require-default-props */
import { navigate } from '@lib/history';
import { Box as BOX } from '@material-ui/core';
import React from 'react';

export type Props = React.ComponentPropsWithoutRef<typeof BOX> &
  React.ComponentPropsWithRef<'div'> & {
    fill?: boolean
    fullWidth?: boolean
    column?: boolean
    borderRadius?: boolean | string | number
    smaller?: boolean
    bigger?: boolean
    line?: boolean
    onClick?: () => void
    href?: string
  }

export const Box = React.forwardRef<typeof BOX, Props>(function Box(
  {
    fullWidth,
    column,
    fill,
    borderRadius,
    children,
    smaller,
    bigger,
    line,
    onClick,
    ...props
  }: Props,
  ref,
) {
  if (onClick && props.href) {
    throw new Error('onClick is not supported if href is provided');
  }

  const box = (
    <BOX
      display="flex"
      fontSize={smaller ? '75%' : bigger ? '125%' : 'inherit'}
      height={fill ? '100%' : undefined}
      flexGrow={fill ? 1 : undefined}
      width={fullWidth || fill ? '100%' : undefined}
      flexDirection={column ? 'column' : undefined}
      borderRadius={borderRadius === true ? 'borderRadius' : borderRadius}
      ref={ref}
      children={children}
      position="relative"
      alignItems={!column && line ? 'center' : undefined}
      onClick={
        onClick
          ? onClick
          : () => {
            if (props.href) {
              navigate(props.href);
            }
          }
      }
      component={props.href ? 'a' : undefined}
      style={{
        cursor: onClick ? 'pointer' : undefined,
      }}
      {...props}
    />
  );

  return box;
});

export default Box;
