import Dialog from '@component/Dialog';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
} from '@material-ui/core';
import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import store from '@lib/store';
import BigListItem from '@component/BigListItem';
import FacilityIcon from '@material-ui/icons/Business';
import { Facility } from '@lib/Facility';
import EditFacility from '@component/EditFacility';

export default observer(function ManageFacilitiesMenu({ open, onClose }) {
  const state = useLocalObservable(() => ({
    editingFacility: null as Facility,
  }));

  return (
    <Dialog open={open} onClose={onClose}>
      {!!state.editingFacility && (
        <EditFacility
          facility={state.editingFacility}
          onClose={() => (state.editingFacility = null)}
        />
      )}
      <DialogTitle>Manage Facilities</DialogTitle>
      <DialogContent>
        <List>
          {store.currentOrganization.facilities.map(facility => (
            <BigListItem
              key={`manage-facility-${facility.id}`}
              button
              onClick={() => (state.editingFacility = facility)}
              LeftIcon={FacilityIcon}
              primaryText={facility.name}
              secondaryText={`${facility.operatingRooms.length} Operating Rooms`}
            ></BigListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back</Button>
      </DialogActions>
    </Dialog>
  );
});
