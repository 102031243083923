/* eslint-disable react/require-default-props */
import {
  Button,
  ButtonBase,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { Box } from '@atoms';
import Dialog from '@component/Dialog';

export default function InfoDialog({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) {
  const [isOpen, setIsOpen] = useState(false),
    onClose = () => setIsOpen(false);

  return (
    <Box pt={0.5}>
      <ButtonBase onClick={() => setIsOpen(true)}>
        <HelpIcon fontSize="small" color="primary" />
      </ButtonBase>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
