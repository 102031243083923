import store from '@lib/store';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

export default observer(function SaveButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      disabled={props.disabled || store.saving}
      endIcon={store.saving ? <CircularProgress /> : undefined}
    />
  );
});
