/* eslint-disable sort-keys */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Check';
import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  Slider,
} from '@material-ui/core';
import { defaultSettings } from '@lib/Case';
import { Box, Loading } from '@atoms';

const defaultMarks = (value: number) => ({ value, label: value });
const sliderProps = {
  'Heart Rate': {
    marks: [40, 80, 120].map(defaultMarks),
    max: 140,
    min: 20,
  },
  'Blood Pressure - Systolic': {
    marks: [70, 100, 130, 160, 190].map(defaultMarks),
    max: 190,
    min: 70,
  },
  'Blood Pressure - Diastolic': {
    marks: [30, 60, 90, 120].map(defaultMarks),
    max: 120,
    min: 30,
  },
  Oxygen: {
    marks: [80, 85, 90, 95].map(defaultMarks),
    max: 100,
    min: 75,
    track: 'inverted',
  },
};

export const ConfigureVital = observer(function ConfigureVital({
  editing,
  initialValue,
  loading,
  name,
  onChange,
  onEditStateChange,
  vitalKey,
}: {
  editing: boolean
  initialValue: number[] | number
  loading: boolean
  name: string
  onChange: (limits: number[] | number) => Promise<void>
  onEditStateChange: (isEditing: boolean) => void
  vitalKey: string
}) {
  const [value, setValue] = useState<number[] | number>(initialValue);
  const [saving, setSaving] = useState<boolean>(false);

  const save = () => {
    setSaving(true);
    onChange(value)
      .then(() => onEditStateChange(false))
      .finally(() => setSaving(false));
  };

  const resetToDefault = () => {
    setValue(defaultSettings[vitalKey]);
    save();
  };

  const buttonProps: any = editing
    ? {}
    : { button: true, onClick: () => onEditStateChange(true) };

  const defaultSettingsDisplay = Array.isArray(defaultSettings[vitalKey])
    ? defaultSettings[vitalKey].join(', ')
    : defaultSettings[vitalKey];

  const valueDisplay = Array.isArray(value) ? value.join(' - ') : value;

  return (
    <ListItem disableGutters divider {...buttonProps}>
      {saving || loading ? (
        <Loading />
      ) : (
        <>
          <Box fontSize="125%" fullWidth column>
            <Box>{name}</Box>
            {editing ? (
              <Box pt={10} pb={2} px={2} column>
                <Slider
                  defaultValue={initialValue}
                  onChangeCommitted={(_event, val: number[]) => {
                    setValue(val);
                  }}
                  valueLabelDisplay="auto"
                  {...sliderProps[name]}
                />
                <Box
                  mt={5}
                  fullWidth
                  line
                  justifyContent="center"
                  color="primary"
                >
                  <Button
                    onClick={resetToDefault}
                    variant="contained"
                    color="primary"
                  >
                    Reset to Default ({defaultSettingsDisplay})
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box color="text.secondary">{valueDisplay}</Box>
            )}
          </Box>
        </>
      )}

      <ListItemIcon>
        <IconButton
          onClick={editing ? save : null}
          style={{ fontSize: '2rem' }}
        >
          {editing ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
});
