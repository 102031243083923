/* eslint-disable sort-keys */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAlert } from '@types';
import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Loading } from '@atoms';
import styles from './alertLog.module.scss';

interface IAlertLogTemplate {
  alerts: IAlert[]
  loading: boolean
}

/**
 * A template for the alert log page
 * Displays all the passed in alerts in a table
 */
export const AlertLogTemplate = ({ alerts, loading }: IAlertLogTemplate) => {
  const navigate = useNavigate();
  const returnToDashboard = () => {
    navigate('/dashboard');
  };

  /**
   * Converts a date from the db to YYYY - MM - dd
   * @param dateString date in string format to be processed
   * @returns The formatted date string
   */
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '-' + month + '-' + day;
  };

  /**
   * Converts a date from the db to HH:mm:ss a
   * @param dateString date in string format to be processed
   * @returns The formatted time string
   */
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  };

  /**
   * Navigates the user to the alert details page
   * @param param.id - ID of the alert to get more details
   */
  const viewDetails = ({ id }) => {
    navigate(id);
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      valueGetter: params =>
        formatDate(params.getValue(params.id, 'createdAt')),
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 110,
      valueGetter: params =>
        formatTime(params.getValue(params.id, 'createdAt')),
    },
    {
      field: 'vitalName',
      headerName: 'Vital',
      width: 110,
    },
    {
      field: 'reported',
      headerName: 'Reported',
      width: 135,
      valueGetter: params => !!params.getValue(params.id, 'reportedFalse'),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      fullWidth
      fill
      column
      px={2}
      flexGrow={1}
      bgcolor="background.dark"
      color="text.primary"
    >
      <Button
        className={styles.returnButton}
        color="primary"
        onClick={returnToDashboard}
        variant="contained"
      >
        Return to Dashboard
      </Button>
      <DataGrid
        onRowClick={viewDetails}
        className={styles.alertTable}
        rows={alerts}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
      />
    </Box>
  );
};

export default AlertLogTemplate;
