/* eslint-disable react/prop-types */
/* eslint-disable sort-vars */
import React, { useEffect, useState } from 'react';
import TimeAgo from 'timeago-react';
import add from 'date-fns/add';
import { Box } from '@atoms';

export default function InPersonCheckCountdown({ dueAt }) {
  dueAt = Date.parse(dueAt);

  const isDue = dueAt < Date.now(),
    isAlmostDue = dueAt < add(Date.now(), { minutes: 4 }),
    [seconds, setSeconds] = useState(0),
    [intervalHandle, setIntervalHandle] = useState<number>();

  useEffect(() => {
    if (isDue) {
      return;
    }
    if (isAlmostDue && intervalHandle) {
      clearInterval(intervalHandle);
    } else {
      clearInterval(intervalHandle);
      setIntervalHandle(window.setInterval(() => setSeconds(seconds + 1), 1000));
    }

    return () => clearInterval(intervalHandle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDue, isAlmostDue]);

  if (!isDue && !isAlmostDue) {
    return null;
  } else
    return (
      <Box color={isDue ? 'error.main' : 'warning.main'} alignItems="center">
        Check-In&nbsp;
        {isDue ? 'Overdue' : <TimeAgo datetime={dueAt} />}
      </Box>
    );
}
