import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get, put } from '@lib/ApiClient';
import { IAlert } from '@types';
import { AlertDetailsTemplate } from '@templates';

/**
 * A template for the alert log page
 * Displays all the passed in alerts in a table
 */
export const AlertDetails = () => {
  const [alert, setAlert] = useState<IAlert>({} as IAlert);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  /**
   * Sends an API request to the details of the specified alert from the database
   */
  const fetchAlert = () => {
    setLoading(true);
    get<IAlert>(`alert_logs/${id}`)
      .then(alertResponse => setAlert(alertResponse))
      .finally(() => setLoading(false));
  };

  const reportAlert = reportedFalse => {
    setLoading(true);
    put<IAlert>(`alert_logs/${id}`, {
      reportedFalse,
    })
      .then(alertResponse => setAlert(alertResponse))
      .finally(() => setLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchAlert, []);

  return (
    <AlertDetailsTemplate
      alert={alert}
      loading={loading}
      report={reportAlert}
    />
  );
};

export default AlertDetails;
