import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAlert } from '@types';
import { Button } from '@material-ui/core';
import { Box, Loading } from '@atoms';
import { GridFields, IGridFields } from '@molecules';
import styles from './alertDetails.module.scss';

interface IAlertDetails {
  alert: IAlert
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  report: Function
}

/**
 * A template for the alert log page
 * Displays all the passed in alerts in a table
 */
export const AlertDetailsTemplate = ({
  alert,
  loading,
  report,
}: IAlertDetails) => {
  const navigate = useNavigate();
  const { createdAt, data, message, reportedFalse } = alert;
  const date = new Date(createdAt);

  /**
   * Returns to the alert log page
   */
  const returnToLog = () => {
    navigate('/alert-log');
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '-' + month + '-' + day;
  };

  const formattedDate = formatDate(date);
  const formattedTime = date.toLocaleTimeString();

  const overviewDatapoints: IGridFields['records'] = [
    {
      label: 'Message',
      value: message,
    },
    {
      label: 'Date Time',
      value: `${formattedDate} ${formattedTime}`,
    },
  ];

  if (reportedFalse) {
    overviewDatapoints.push({
      label: 'Reported as Invalid',
      warning: true,
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      fullWidth
      fill
      column
      px={2}
      flexGrow={1}
      bgcolor="background.dark"
      color="text.primary"
      className={styles.alertDetails}
    >
      <Button
        className={styles.returnButton}
        color="primary"
        onClick={returnToLog}
        variant="contained"
      >
        Return to Log
      </Button>

      <h2>Overview</h2>

      <GridFields records={overviewDatapoints} />

      {!reportedFalse ? (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => report(true)}
        >
          Report as Invalid
        </Button>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={() => report(false)}
        >
          Report as Valid
        </Button>
      )}

      <hr />

      <h2>Details</h2>

      {data?.map((vitalReading, index) => {
        const { bloodPressure, co2, frameUrl, heartRate, oxygen, time } =
          vitalReading;

        const [bpa, bpb] = bloodPressure;

        const dataPoints: IGridFields['records'] = [
          {
            label: 'Heart Rate',
            value: heartRate.toString(),
          },
          {
            label: 'Oxygen',
            value: oxygen.toString(),
          },
          {
            label: 'Blood Pressure',
            value: `${bpa} / ${bpb}`,
          },
          {
            label: 'Co2',
            value: co2.toString(),
          },
          {
            label: 'Recorded At',
            value: new Date(time).toLocaleTimeString(),
          },
        ];

        return (
          <div className={styles.vitalReading} key={index}>
            <img src={frameUrl} alt="Vital Frame" />

            <GridFields records={dataPoints} />
          </div>
        );
      })}
    </Box>
  );
};

export default AlertDetailsTemplate;
