import IMask from '@lib/IMask';
import { useEffect, useRef } from 'react';

export default function useMask({
  mask,
  onChange,
}: {
  mask: any
  onChange: (unmaskedValue: string) => void
}) {
  const ref = useRef();

  useEffect(() => {
    if (!ref?.current) return;

    const imask = IMask(ref.current, {
      mask,
    });

    imask.on('accept', () => {
      const { unmaskedValue } = imask;

      onChange(unmaskedValue);
    });

    return () => imask.destroy();
  }, [mask, onChange]);

  return { ref };
}
