const alertPathPattern = /alerts\/([^/]+)$/;

export function isAlertPath(pathname: string, alertId?: string): boolean {
  if (alertId) {
    return !!pathname.match(`alerts/${alertId}`);
  } else {
    return !!pathname.match(alertPathPattern);
  }
}

export function extractAlertId(pathname: string): false | string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, alertId] = pathname.match(alertPathPattern);

  return alertId;
}
