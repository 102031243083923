/* eslint-disable react/prop-types */
import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import { Box } from '@atoms';

export default function BigListItem({
  children = null,
  primaryText = null,
  secondaryText = null,
  LeftIcon = null,
  RightIcon = null,
  ...props
}) {
  const icon = (IconComponent: typeof SvgIcon) =>
    IconComponent ? (
      <ListItemIcon>
        <Box fontSize="1.5rem">
          <IconComponent fontSize="inherit" />
        </Box>
      </ListItemIcon>
    ) : null;

  return (
    <ListItem {...props}>
      {icon(LeftIcon)}
      <Box bigger fullWidth line justifyContent="space-between">
        {primaryText && (
          <ListItemText primary={primaryText} secondary={secondaryText} />
        )}
        {children}
      </Box>
      {icon(RightIcon)}
    </ListItem>
  );
}
