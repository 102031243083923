/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { post } from '@lib/ApiClient';
import useFieldState from '@lib/useFieldState';
import Dialog from '@component/Dialog';
import PhoneField from '@component/PhoneField';
import { Box } from '@atoms';

export function InviteUserDialog({
  open,
  onClose,
  organization: { id: organizationId, name: orgName },
}) {
  const [email, emailFieldProps] = useFieldState(''),
    [name, nameFieldProps] = useFieldState(''),
    [title, titleFieldProps] = useFieldState(''),
    [phone, setPhone] = useState(''),
    disableSubmit = !(phone.length || email.length),
    onSubmit = async () => {
      await post('users', {
        name,
        title,
        phone,
        organizationId,
      });

      onClose();
    };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <p>
          Add a new user to<strong>&nbsp;{orgName}</strong>. Provide their
          email, phone, or both.
        </p>
        <Box py={1}>
          <TextField fullWidth label="Name" {...nameFieldProps} />
        </Box>
        <Box py={1}>
          <TextField fullWidth label="Title" {...titleFieldProps} />
        </Box>
        <Box py={1}>
          <TextField fullWidth label="Email" {...emailFieldProps} />
        </Box>
        <Box py={1}>
          <PhoneField onChange={setPhone} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} disabled={disableSubmit}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
