/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import { IVitals } from '@types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@atoms';
import styles from './monitorViews.module.scss';

function Value({ label, name, value }) {
  const topOffset = ['2px', '5px', '8px'];
  const negativeTopOffset = topOffset.map(val => `-${val}`);

  return (
    <Box data-vital-name={name} mt={negativeTopOffset}>
      <Box fontSize={['50%', '75%', '85%']} mt={topOffset}>
        {label}
      </Box>
      <Box
        color={name}
        column
        data-vital-value
        fontSize={['140%', '160%', '225%']}
        justifyContent="flex-start"
        pl={[0.5, 1, 1]}
      >
        {value}
      </Box>
    </Box>
  );
}

/**
 * Renders a table that lists all recently recorded vital readings
 * @param {IVitals[]} props.vitalSet - Array of all vital records recently captured
 * @returns React Node
 */
const VitalTable = ({ vitalSet }: { vitalSet: IVitals[] }) => {
  const columns = [
    {
      render: row => `${row.bloodPressure[0]} / ${row.bloodPressure[1]}`,
      title: 'Blood Pressure',
    },
    {
      render: row => row.heartRate,
      title: 'Heart Rate',
    },
    {
      render: row => row.oxygen,
      title: 'SpO2',
    },
    {
      render: row => row.co2,
      title: 'CO2',
    },
  ];

  /**
   * Renders all column headers for the table
   * @returns Header TableCell React Nodes
   */
  const renderHeaderCells = () => {
    return columns.map(col => (
      <TableCell key={col.title}>{col.title}</TableCell>
    ));
  };

  /**
   * Renders a single table cell for the passed in row + column combo
   * @param row - VitalSet record to render
   * @param col - Column to render
   * @returns Table Cell React Node
   */
  // eslint-disable-next-line react/display-name
  const renderCell = row => col =>
    <TableCell key={col.title}>{col.render(row)}</TableCell>;

  /**
   * Renders an entire row for each vitalSet record
   * @param row - VitalSet record to render
   * @returns Table Row React Node
   */
  const renderRow = (row, index) => {
    return <TableRow key={index}>{columns.map(renderCell(row))}</TableRow>;
  };

  return (
    <TableContainer className={styles.vitalTable}>
      <Table size="small">
        <TableHead>
          <TableRow>{renderHeaderCells()}</TableRow>
        </TableHead>
        <TableBody>
          {vitalSet.sort((a, b) => b.time - a.time).map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const VitalsDisplay = ({
  vitals,
  vitalSet,
}: {
  vitals?: IVitals
  vitalSet: IVitals[]
}) => {
  return (
    <Box fill column justifyContent="space-between" className="vitals-display">
      <Box fullWidth justifyContent="space-between">
        <Value
          name="bloodPressure"
          label="NBP"
          value={
            <Box column>
              <Box>{vitals?.bloodPressure?.[0] || '---'}</Box>
              <Box mt={[-0.25, -0.5, -1]}>
                {vitals?.bloodPressure?.[1] || '--'}
              </Box>
            </Box>
          }
        />
        <Value name="heartRate" label="HR" value={vitals?.heartRate || '--'} />
        <Value name="oxygen" label="SpO2" value={vitals?.oxygen || '--'} />
        <Value name="co2" label="etCO2" value={vitals?.co2 || '--'} />
      </Box>
      <VitalTable vitalSet={vitalSet} />
    </Box>
  );
};

export default VitalsDisplay;
