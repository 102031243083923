/* eslint-disable react/prop-types */
import React from 'react';
import { matchSorter } from 'match-sorter';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Facility } from '@lib/Facility';
import store from '@lib/store';
import { Box } from '@atoms';

export default function SelectFacility({ onChange, facilities, defaultValue }) {
  return (
    <Box>
      <Autocomplete
        autoComplete
        autoSelect
        openOnFocus
        blurOnSelect
        defaultValue={defaultValue}
        getOptionLabel={(option: Facility) => option.name}
        getOptionDisabled={(option: Facility) =>
          option.operatingRooms.every(({ id }) =>
            store.monitors.find(
              ({ operatingRoomId }) => operatingRoomId === id,
            ),
          )
        }
        options={facilities}
        filterOptions={(options, { inputValue }) =>
          matchSorter(options, inputValue, { keys: ['name'] })
        }
        onChange={(_, facility) => onChange(facility)}
        renderInput={props => (
          <TextField
            {...props}
            name="facility"
            label="Facility"
            helperText="Search by name, address..."
          />
        )}
      />
    </Box>
  );
}
