/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import LogRocket from 'logrocket';
import { createRoot } from 'react-dom/client';
import App from '@component/App';
import * as Sentry from '@sentry/react';
import store from '@lib/store';
import { IAppError } from '@types';

const supportBase = 'http://support.vigilantmedicalsolutions.com/en/articles/';
const installArticle = '5269291-first-steps';
const privacyArticle = '5296943-privacy-policy';
const redirectToArticle = (article: string) => window.location.assign(`${supportBase}${article}`);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    beforeSend(
      event,
      {
        originalException: error,
      }: { originalException: IAppError | string | null },
    ) {
      if (error && typeof error !== 'string') {
        error.pushed = true;
        store.errors.push(error);
      }

      return event;
    },
    dsn: 'https://7fc239d5a01b4693ad28a3f6c8d2d718@o48728.ingest.sentry.io/5473442',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.01,
  });

  LogRocket.init('5xd3tx/guardian');

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();

  // @ts-ignore
  module.hot.addStatusHandler((status: string) => {
    // eslint-disable-next-line no-console
    if (status === 'prepare') console.clear();
  });
}

if (window.location.hostname.match(/^install\./)) {
  redirectToArticle(installArticle);
} else if (window.location.pathname === '/privacy') {
  redirectToArticle(privacyArticle);
} else {
  createRoot(document.getElementById('root')).render(<App />);
}
