/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import store from '@lib/store';
import useSubmenu from '@lib/useSubmenu';
import Dialog from '@component/Dialog';
import ManageOrganizationMenu from '@component/ManageOrganizationMenu';
import BigListItem from '@component/BigListItem';
import { destroy, put } from '@lib/ApiClient';
import Alerts from '@component/Alerts';
import Confirm from '@component/Confirm';
import { Suspense } from '@atoms';
import { TopBar } from '@organisms';
import styles from './appMenu.module.scss';

const ImpersonateUser = React.lazy(() => import('@component/ImpersonateUser')),
  NotificationSettings = React.lazy(
    () => import('@component/NotificationSettings'),
  );

export const AppMenu = observer(function AppMenu() {
  const { submenu, closeSubmenu, setSubmenu, makeSubmenuSetter } = useSubmenu({
    names: [
      'choose-org',
      'manage-org',
      'impersonate-user',
      'notification-settings',
    ],
  });

  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    confirmingOrgId: null as string,
    confirmingRemoveAllORs: false,
    loading: false,
    open: false,
    renderWithError: false,

    removeAllMonitors() {
      store.userCable.perform('add_all_monitors', {});
      state.close();
    },

    async stopImpersonating() {
      store.saving = true;
      await destroy('impersonate');
      await store.loadCurrentUser(true);
      store.saving = false;
      state.close();
    },

    async toggleKeepMonitors() {
      const response = await put('users', {
        keepMonitors: !store.user.keepMonitors,
      });

      Object.assign(store.user, response);
    },

    toggleAdminView() {
      store.adminView = !store.adminView;
      state.close();
    },

    close() {
      state.open = false;
      closeSubmenu();
      state.loading = false;
    },
  }));

  const { organizations, currentOrganization } = store;
  const hasMultipleOrgs = organizations.length > 1;
  const showFakeAlerts =
    store.alerts.length && store.currentOrganization?.features.fakeAlerts;

  useEffect(() => {
    if (state.renderWithError) {
      state.renderWithError = false;
      throw new Error('test rendering error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.renderWithError]);

  const confirmSelectOrganization = async () => {
    state.loading = true;
    await store.setOrganization(state.confirmingOrgId);
    state.close();
  };

  /**
   * If there are multiple organizations this will open the choose-org submenu
   */
  const onOrgClick = () => {
    if (!hasMultipleOrgs) {
      return;
    }
    setSubmenu('choose-org');
  };

  /**
   * Renders something different if the user is impersonating a user or has the ability to imporsonate a user
   * @returns a renderable react node
   */
  const renderImpersonatingButton = () => {
    if (store.user.impersonating) {
      return (
        <BigListItem button onClick={() => state.stopImpersonating()}>
          <ListItemText primary="Stop Impersonating" />
        </BigListItem>
      );
    }

    if (store.user.admin) {
      return (
        <BigListItem button onClick={() => setSubmenu('impersonate-user')}>
          <ListItemText primary="Impersonate a User" />
        </BigListItem>
      );
    }

    return;
  };

  /**
   * Redirects the user (admin level) to the alert log page
   */
  const navigateToAlerts = () => {
    state.close();
    navigate('/alert-log');
  };

  /**
   * Renders the admin exclusive buttons
   * @returns a renderable react node
   */
  const renderAdminButtons = () => {
    if (!store.user.admin) {
      return;
    }

    return (
      <>
        <BigListItem
          button
          onClick={state.toggleAdminView}
          primaryText={`${store.adminView ? 'Disable' : 'Enable'} Dev View`}
        />

        <BigListItem
          button
          onClick={navigateToAlerts}
          primaryText="View Alerts"
        />
      </>
    );
  };

  /**
   * Removes all the ORs the user currently has visible
   */
  const removeAllORs = () => {
    state.confirmingRemoveAllORs = false;
    store.userCable.perform('clear_monitors', {});
    state.close();
  };

  if (!store.performedInitialUserFetch || !store.user) {
    return null;
  }

  return (
    <TopBar basePadding={1} hideLogoType bgcolor="#000000" color="white">
      <Backdrop open={state.loading} className={styles.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <IconButton onClick={() => (state.open = true)} color="inherit">
        <MenuIcon />
      </IconButton>

      <Dialog
        open={state.open}
        onClose={state.close}
        container={() => document.getElementById('app')}
        disableEnforceFocus={true}
      >
        <DialogTitle>Guardian</DialogTitle>
        <DialogContent>
          <List>
            <BigListItem>
              <ListItemText primary={store.user.name} secondary="this is you" />
            </BigListItem>

            <BigListItem
              button={hasMultipleOrgs || undefined}
              RightIcon={hasMultipleOrgs ? EditIcon : null}
              onClick={onOrgClick}
            >
              <ListItemText
                primary={currentOrganization?.name}
                secondary={`${
                  hasMultipleOrgs ? 'Current ' : 'Your '
                } organization ${hasMultipleOrgs ? ' - tap to change' : ''}`}
              />
            </BigListItem>

            {store.currentOrganization.facilities.length === 1 && (
              <BigListItem
                button
                primaryText="Add All ORs"
                secondaryText={'Monitor every OR at your facility'}
                onClick={() => {
                  store.userCable.perform('add_all_monitors', {});
                  state.close();
                }}
                RightIcon={AddIcon}
              />
            )}

            {!!store.monitors.length && (
              <>
                <BigListItem
                  button
                  primaryText="Remove All ORs"
                  secondaryText={'Stop monitoring all of your ORs'}
                  onClick={() => (state.confirmingRemoveAllORs = true)}
                  RightIcon={ClearIcon}
                />
                <Confirm
                  open={state.confirmingRemoveAllORs}
                  title="Remove all ORs?"
                  content="Your dashboard will be cleared and you will stop receiving alerts"
                  onCancel={() => (state.confirmingRemoveAllORs = false)}
                  onConfirm={removeAllORs}
                />
              </>
            )}

            {currentOrganization.membership.admin && (
              <BigListItem
                button
                onClick={makeSubmenuSetter('manage-org')}
                RightIcon={SettingsIcon}
              >
                <ManageOrganizationMenu
                  onClose={closeSubmenu}
                  open={submenu === 'manage-org'}
                  organization={currentOrganization}
                />
                <ListItemText
                  primary={`Manage ${currentOrganization?.name}`}
                  secondary="You are an organization administrator"
                />
              </BigListItem>
            )}

            {renderImpersonatingButton()}
            {renderAdminButtons()}

            {!!store.currentOrganization.features.notificationSettings && (
              <BigListItem
                button
                onClick={makeSubmenuSetter('notification-settings')}
                RightIcon={NotificationsIcon}
                primaryText="Notification Settings"
              />
            )}

            <BigListItem
              button
              onClick={() => store.logOut()}
              RightIcon={ExitIcon}
              primaryText="Sign Out"
            />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => state.close()}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={submenu === 'notification-settings'}
        onClose={() => closeSubmenu()}
      >
        <DialogTitle>Notification Settings</DialogTitle>
        <DialogContent>
          <Suspense>
            <NotificationSettings />
          </Suspense>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubmenu}>Back</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={submenu === 'choose-org'} onClose={() => closeSubmenu()}>
        <DialogTitle>Choose Organization</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            onChange={({ target: { value } }) =>
              (state.confirmingOrgId = value)
            }
            id="choose-org"
            label="Select Organization"
            defaultValue={currentOrganization?.id}
          >
            {organizations?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubmenu}>Back</Button>
          {state.confirmingOrgId && (
            <Button onClick={confirmSelectOrganization}>Confirm</Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={submenu === 'impersonate-user'}
        onClose={() => closeSubmenu()}
      >
        <Suspense>
          <ImpersonateUser
            onCancel={closeSubmenu}
            onComplete={() => {
              closeSubmenu();
              state.close();
            }}
          />
        </Suspense>
      </Dialog>

      {!!showFakeAlerts && <Alerts />}
    </TopBar>
  );
});

export default AppMenu;
